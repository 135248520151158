<template>
  <div>
    <div v-show="!isModify">
      <!-- 下载模块 -->
      <div
        style="
          border: 1px, solid, rgb(221, 221, 221);
          height: auto;
          background-color: rgb(245, 250, 254);
          margin-bottom: 10px;
        "
      >
        <div>
          <!-- 查询栏 -->
          <div class="cxl">
            <el-form
              :inline="true"
              :model="formLabelAlign"
              class="demo-form-inline"
            >

              <el-form-item label="投保人" class="custom-form-item">
             
                  <el-select   v-model="formLabelAlign.applicantName"
                  placeholder="请输入投保人"
                  :disabled="roleId!='role_000000000000000001' && roleId!='role_000000000000000002'"
                  class="cxl-input">
                  <el-option v-for="item in tableDict" :key="item.id"  :label='item.roleCompany' :value="item.roleCompany"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item class="cxl-form-btns">
                <el-button class="cxl-form-btn"  @click="onSubmit"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- table模块  ref表格数据 //querytable重新请求-->
      <div>
        <el-table
        
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      prop="insName"
      label="险种">
    </el-table-column>
    <el-table-column
      prop="applicantName"
      label="投保人">
    </el-table-column>
    <el-table-column
      prop="preStoreTotalPrice"
      label="预存总金额">
    </el-table-column>
    <el-table-column
      prop="remainingBalance"
      label="余额">
    </el-table-column>
    <el-table-column
      prop="status"
      label="状态">
    </el-table-column>
    <el-table-column
      label="操作">
      <template slot-scope="scope">
        
          <i
            class="el-icon-s-marketing"
            title="预存记录"
            @click="handleModify(scope.$index, scope.row)"
          >预存记录</i>
         
        </template>
    </el-table-column>
  </el-table>
      </div>
    </div>
    <div v-show="isModify">
        <!-- 查询栏 -->
        <div class="cxl">
            <el-form
              :inline="true"
              :model="ycjl"
              class="demo-form-inline"
            >

              <el-form-item label="投保人" class="custom-form-item">
             
                  <el-select   v-model="ycjl.applicantName"
                  placeholder="请输入投保人"
                  :disabled="roleId!='role_000000000000000001' && roleId!='role_000000000000000002'"
                  class="cxl-input">
                  <el-option v-for="item in tableDict" :key="item.id"  :label='item.roleCompany' :value="item.roleCompany"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item class="cxl-form-btns">
                <el-button class="cxl-form-btn"  @click="onoverSubmit"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
          </div>
      <el-table
          ref="multipleTable"
          :row-class-name="tableRowClassName"  
    :data="tablleDataXq"
    border
          @selection-change="handleSelectionChange"
    style="width: 60%">
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column
      prop="insName"
      label="险种">
    </el-table-column>
    <el-table-column
      prop="applicantName"
      label="投保人"
      width="180">
    </el-table-column>
    <el-table-column
      prop="prestorePrice"
      label="预存金额">
    </el-table-column>
    <el-table-column
    prop="status"
    label="状态">
    </el-table-column>

  </el-table>
  <div style="margin-top: 5px">
      <el-button class="table-bottom-btns" style=" background-color: rgba(0, 150, 136);" @click="toggleSelection()">
        <i style="font-size: 15px" class="el-icon-s-release"></i
        >取消选择</el-button
      >
      <el-button
        class="table-bottom-btns"
          style="background-color: rgb(231, 102, 109)"
              type="danger"
         @click="handleModifyBack()"
      >
        <i style="font-size: 15px" class="el-icon-back"></i
        >退出</el-button
      >
       <el-button
        class="table-bottom-btns"
        style="background-color: rgb(31, 02, 19)"
                      type="info"
         @click="handleDeleteOA()"
      >
        <i style="font-size: 15px" class="el-icon-s-promotion"></i
        >提交OA</el-button
      >
      <el-button class="table-bottom-btns" @click="tjfj">
                <i style="font-size: 20px" class="el-icon-upload"></i>上传excel
              </el-button>
                <!-- 上传excel -->
                <el-dialog
                title="上传excel"
                :visible.sync="tjfjVisble"
                :close-on-click-modal="false"
              >
                <div style="display: flex; justify-content: center">
                  <el-upload
                    style="width: auto"
                    class="centerImg"
                    :file-list="fileList"
                    ref="uploadicon"
                    :action='url'
                 
                    :auto-upload="true"
                    :before-upload="handleBeforeUpload"

                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple
                    drag
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或<em>点击上传</em>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                      只能上传xlsx文件，且不超过1MB
                    </div>
                  </el-upload>
                </div>
    
              </el-dialog>
    </div>

  <div v-if="tablleDataXq.length" style="text-align: left; margin-top: 5px">
      <el-pagination
        @size-change="handleSizeChangexq"
        @current-change="handleCurrentChangexq"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCountXq"
      >
      </el-pagination>
    </div>
    </div>
  </div>
</template>
<script>
// 引入table top 组件
import xtTableTop from '@/components/Public/Tabletop/index.vue'
// 引入table组件
import xtTable from '@/components/Public/Table/index.vue'
import * as XLSX from 'xlsx';  //导出类型
import axios from 'axios'; 
import { getYcglData,getYcglXq,getPushOa,getTbrxx } from '@/axios/ycgl.js'
export default {
    components: {
        xtTable,
        xtTableTop
    },
    data() {
        return {
          tableDict:[],
          url:window.ipConfigUrl.baseURL1+"import/decl/vehicleEmpLiabilityIns/InsPriceData",
            templateId: '1', // 这个就是页面的ref 用来判定需要哪一个模版  
             // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 个数选择器（可修改）
      pageSizes: [50, 100],
      // 默认每页显示的条数（可修改）
      PageSize: 50,
            tableData: [],
            tablleDataXq:[],
            totalCountXq: 1,
        formLabelAlign: {
        current: 1,
        size: 50,
        insType: "",
        applicantName:'',
        username:JSON.parse(localStorage.getItem("username")),
        roleId:JSON.parse(localStorage.getItem("roleId")),
      },
      ycjl: {
        applicantName:'',

      },

        // 编辑
        isModify:false,
        bjModify:{
          applicantName:'', //row.id
          insType:"04",
          size:'50',
          current:'1',
          username:JSON.parse(localStorage.getItem("username")),
          roleId:JSON.parse(localStorage.getItem("roleId")),
        },
        multipleSelection: [], // 用于存储选中项
        username:JSON.parse(localStorage.getItem("username")),
        roleName:JSON.parse(localStorage.getItem("roleName")),
        roleId:JSON.parse(localStorage.getItem("roleId")),
        records:[],
        userInsType:'',
        fileList: [], // 用于 理赔存储上传的文件列表
        tjfjVisble: false, //添加附件
        // 假数据结构
        tabData:[
          {
            applicantName: "-",
insName: "责意险",
insType: "-",
preStoreTotalPrice: "-",
remainingBalance: "-",
status: "-",
          },
          {
            applicantName: "-",
insName: "车辆雇主责任险",
insType: "-",
preStoreTotalPrice: "-",
remainingBalance: "-",
status: "-",
          },
          {
            applicantName: "-",
insName: "货运三者险",
insType: "-",
preStoreTotalPrice: "-",
remainingBalance: "-",
status: "-",
          }, {
            applicantName: "-",
insName: "商业险",
insType: "-",
preStoreTotalPrice: "-",
remainingBalance: "-",
status: "-",
          }, {
            applicantName: "-",
insName: "交强险",
insType: "-",
preStoreTotalPrice: "-",
remainingBalance: "-",
status: "-",
          }, {
            applicantName: "-",
insName: "财产险",
insType: "-",
preStoreTotalPrice: "-",
remainingBalance: "-",
status: "-",
          }, {
            applicantName: "-",
insName: "货运险",
insType: "-",
preStoreTotalPrice: "-",
remainingBalance: "-",
status: "-",
          }, 
        ]
        }
    },
    methods: {
          // 判断table业务状态进行css样式修改
    tableRowClassName({row, rowIndex}) {  
      if (row.status === '导入失败') {     
        return 'orange-row';  
      } 
       if (row.status === 'OA审核不通过') {  
        return 'red-row';  
      }  
      // 默认情况下不返回任何类名，或返回一个默认类名  
      return '';  
    } ,
        // 导出xt-table中的table数据
        handleExport() {
            // 从TableComponent获取数据  
            const tableData = this.$refs.tableComponent.getTableData();

            // 将数据转换为工作表  
            const ws = XLSX.utils.json_to_sheet(tableData);

            // 创建一个工作簿并添加工作表  
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 导出xlsx文件  
            XLSX.writeFile(wb, `表格数据_${this.templateId}.xlsx`);
        },
            // 获取表单所有数据
    allData(e) {
      if (this.roleName=='管理员' || this.roleName=='超级管理员') {
        
      }
      getYcglData(e).then((res) => {
        if (res.code == 200) {
            this.tableData = res.data;
            console.log(res.data);
            
            this.tabData = this.tabData.filter(tabItem => {  
      // 检查 tabItem 的 insType 是否不在 tableData 的任何 insType 中  
      return !this.tableData.some(tableItem => tableItem.insName === tabItem.insName);  
    });  
    this.tableData=[...this.tableData,...this.tabData]
    console.log(this.tableData);
    
          // 获取总数和保费信息
          // this.$message.success("获取表格数据成功");
        }
      });
    },
        // 点击 上传附件
        tjfj() {
          if (this.ycjl.applicantName=='') {
            this.$message.error("请选择投保人"); return;
          }else{
            this.fileList = [];
            this.tjfjVisble = true;
          }
     
    },
    handleBeforeUpload(file) {  
      // 在这里不阻止上传，而是触发自定义的上传逻辑  
      
      this.queryDownFile=window.ipConfigUrl.baseURL1+'import/presave/insdata'

      this.customUpload(file,this.queryDownFile);  
      // 返回false以阻止Element UI的默认上传行为  
      return false;  
    },  
    customUpload(file,downFile) {  
      // 自定义上传逻辑，可以添加username到请求体中  
      const formData = new FormData();  
      formData.append('file', file); // 假设file.raw是文件对象，具体取决于你的实际情况  
      formData.append('username', this.username);  
      formData.append('roleCompany', this.ycjl.applicantName);  
      // 使用axios或其他HTTP客户端发送请求  
      axios.post(downFile, formData, {  
        headers: {  
          'Content-Type': 'multipart/form-data',  
        },  
      })  
      .then(response => { 
         
        if (response.data.code==200) {
          this.$message({
            type:'success',
            message:response.data.message,
          });
                              // 重新获得表格数据
        this.allData(this.formLabelAlign);   
        }else{
          this.$message({
            type:'error',
            message:'提交错误',
          });
        }
        this.tjfjVisble = false;

      })  
      .catch(error => {  
        // 处理上传失败后的逻辑  
        console.error(error);  
      });  
    }, 
        // 选中table 复选框数据
        handleSelectionChange(val) {
      this.multipleSelection = val;
    },
        // 表格取消 复选
        toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
        // 提交OA
        handleDeleteOA(){
      if (this.multipleSelection.length==0) {
        this.$message({
          type: "warning",
          message: "请选择要提交的数据!",
        });
        return;
      }else{
        this.$confirm("此操作将提交OA, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let insType=[]
        insType= this.multipleSelection.map((item) => item.insType).filter((value, index, self) => {  
            return self.indexOf(value) === index;  
        });
        if (insType.length>1) {
          this.$message({
            type:'warning',
            message:'勾选的险种类型不一致，请重新勾选！',
          });
          return;
        }else{
          this.userInsType=insType[0]
        }
        
        this.records = this.multipleSelection.map((item) => item.id);
        
        // 运输管理
        getPushOa({ username:this.username,insType:this.userInsType,records: this.records }).then((res) => {
          this.isModify = false; //返回页面首页
          this.allData(this.formLabelAlign);
          this.records = [];
        })
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      }
      
    },
    handleSizeChangexq(val) {
      this.PageSize = val;
      this.bjModify.size=val;
       this.getbjData()
    },
    handleCurrentChangexq(val) {
      this.currentPage = val;
      this.bjModify.current=val;
       this.getbjData()
    },
    // 查询
    onSubmit() {
      // 初始化请求table数据
      this.allData(this.formLabelAlign);
    },
      // 导出excel
    exportToExcel() {
      this.handleExport();
    },
    getbjData(){
      getYcglXq(this.bjModify)
      .then(res=>{
       if (res.data.records) {
        this.tablleDataXq=res.data.records;
        this.totalCountXq=res.data.total;
       }else{
        this.tablleDataXq=[];
        this.totalCountXq=0;
       }
      })
    },
        // 预存记录 查询
        onoverSubmit(){
          this.bjModify.applicantName=this.ycjl.applicantName;
          // this.bjModify.insType=row.insType;
      //     ycjl: {
      //   applicantName:'',

      // },
      this.getbjData()
    },  
            // 编辑
    handleModify(index, row) {
      this.isModify = true; //打开编辑页面
      this.bjModify.applicantName=row.applicantName;
      this.bjModify.insType=row.insType;
     this.getbjData()
    },
    handleModifyBack(){
      this.isModify = false; //打开编辑页面
},
    
    },
    mounted() {

          // 初始化请求table数据

    if (this.roleId=='role_000000000000000001' || this.roleId=='role_000000000000000002') {
      this.ycjl.applicantName='全部';
      this.formLabelAlign.applicantName='全部';
      this.allData(this.formLabelAlign);
    }
    if (this.roleId!='role_000000000000000001' && this.roleId!='role_000000000000000002') {
      this.ycjl.applicantName=this.roleName;
      this.formLabelAlign.applicantName=this.roleName;
      this.allData(this.formLabelAlign);
    }
    
    getTbrxx({username: this.username,
  roleId:this.roleId}).then(res=>{
      console.log(res,'参数');
      if (res.code==200) {
        this.tableDict=res.data;
       if(this.roleId=='role_000000000000000001' || this.roleId=='role_000000000000000002'){
        let a={
          roleName: "888",
          roleCompany: "全部",
        }
        this.tableDict.unshift(a);
       }
        console.log(this.tableDict,'字典');
      }
      
    })
    },
}
</script>
<style lang="less" scoped>
/deep/.el-table .orange-row {  
  background-color: orange !important; /* 橙黄色 */  
}  
  
/deep/.el-table .red-row {  
  background-color: red !important; /* 红色 */  
} 
.table-bottom-btns {
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  font-size: 12px;
  color: white;
  font-weight: 900;
  background-color: rgba(0, 150, 136);
}

.table-bottom-btns:hover {
  opacity: 0.8;
  /* 举例使用 Element UI 的主题色作为文字颜色 */
}
    // 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
</style>
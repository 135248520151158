<template>
  <div>
    <div v-show="!isLp">
      <!-- 下载模块 -->
      <div
        style="
          border: 1px, solid, rgb(221, 221, 221);
          height: auto;
          background-color: rgb(245, 250, 254);
          margin-bottom: 10px;
        "
      >
        <div>
          <!-- 下载栏 -->
          <div class="xzl">
            <div class="xzl-div-btn">
              <el-button
                class="xzl-div-btns"
                style="
                  height: 43px;
                  margin-left: 10px;
                  background-color: rgb(231, 102, 109);
                  line-height: 43px;
                "
                @click="tjxx"
              >
                <i style="font-size: 16px" class="el-icon-plus"></i>添加信息
              </el-button>
            </div>
            <div class="xzl-xzmb" @click="downloadTemplate">下载模版</div>
            <div class="xzl-div-btn">
              <el-button class="xzl-div-btns" @click="tjfj">
                <i style="font-size: 20px" class="el-icon-upload"></i>上传excel
              </el-button>
              <!-- 上传excel -->
              <el-dialog
                title="上传excel"
                :visible.sync="tjfjVisble"
                :close-on-click-modal="false"
              >
                <div style="display: flex; justify-content: center">
                  <el-upload
                    style="width: auto"
                    class="centerImg"
                    :file-list="fileList"
                    ref="uploadicon"
                    :action='url'
                    :on-remove="handleRemove"
                    :before-upload="handleBeforeUpload"
                    :auto-upload="true"
                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple
                    drag
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或<em>点击上传</em>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                      只能上传xlsx文件，且不超过1MB
                    </div>
                  </el-upload>
                </div>
              </el-dialog>
            </div>
          </div>
          <!-- 查询栏 -->
          <div class="cxl">
            <el-form
              :inline="true"
              :model="formLabelAlign"
              class="demo-form-inline"
            >
              <el-form-item label="货运险类型" class="custom-form-item">

                <el-select v-model="formLabelAlign.freightType" placeholder="请选择货运险类型">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
                        class="cxl-input"
      :value="item.value">
    </el-option>
  </el-select>
              </el-form-item>
              <el-form-item label="物流公司" class="custom-form-item">
                <el-input
                  v-model="formLabelAlign.logisticsCompany"
                  placeholder="请输入物流公司"
                  class="cxl-input"
                ></el-input>
              </el-form-item>
              <el-form-item class="cxl-form-btns">
                <el-button class="cxl-form-btn" @click="onSubmit"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- table模块  ref表格数据-->
      <div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
        >
          <el-table-column
            v-for="(item, index) in tableTop"
            align="center"
            header-align="center"
            :key="index"
            :label="item.fieldName"
            :prop="item.field"
          >
          </el-table-column>
          <el-table-column
            v-if="tableTop.length > 0"
            header-align="center"
            align="center"
            label="操作"
            width="150"
            style="display: flex; justify-content: left"
          >
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                title="修改"
                @click="handleModify(scope.$index, scope.row)"
              ></i>
              <i
                class="el-icon-delete"
                title="删除"
                @click="clickNowRow(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="tableData.length" style="text-align: left; margin-top: 5px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    </div>
    <!-- 信息添加 -->
    <div v-show="isLp">
      <div style="margin: 20px">
        <h3>{{ isAdd ? "添加信息" : "编辑信息" }}</h3>
        <el-form ref="form" :model="form" label-width="120px">
          <!-- 新增显示 -->
          <el-form-item v-if="isAdd"  label="货运险保费类型">
            <el-select  v-model="form.freightType" placeholder="请选择货运险类型">
    <el-option
                  style="width: 30vw"
      v-for="item in options"
      :key="item.value"
      :label="item.label"
                        class="cxl-input"
      :value="item.value">
    </el-option>
  </el-select>


          </el-form-item>
          <el-form-item label="物流公司">
            <el-input
              v-model="form.logisticsCompany"
              style="width: 30vw"
            ></el-input>
          </el-form-item>
          <el-form-item label="公里数">
            <el-input v-model="form.kilometers" style="width: 30vw"></el-input>
          </el-form-item>
          <el-form-item label="保险费">
            <el-input
              v-model="form.insuranceFee"
              style="width: 30vw"
            ></el-input>
          </el-form-item>
          <el-form-item label="车价">
            <el-input v-model="form.carPrice" style="width: 30vw"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="bcbj">保存</el-button>
            <el-button @click="qxbj">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
// 引入table top 组件
import xtTableTop from "@/components/Public/Tabletop/index.vue";
// 引入table组件
import xtTable from "@/components/Public/Table/index.vue";
import axios from 'axios';  
import * as XLSX from "xlsx"; //导出类型
import { saveAs } from "file-saver";
// 引入syx.JS sbglJqxData,
import { newCardata, newCarxg,deleteBfsz,downLoad } from "@/axios/Xtsz/Xtsz/hyxbfsz.js";
export default {
  components: {
    xtTable,
    xtTableTop,
  },
  data() {
    return {
            // 默认显示第几页
            currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 个数选择器（可修改）
      pageSizes: [50, 100],
      // 默认每页显示的条数（可修改）
      PageSize: 50,
      templateId: "1", // 这个就是页面的ref 用来判定需要哪一个模版
      tableTop: [
        { field: "id", fieldName: "ID" },
        { field: "logisticsCompany", fieldName: "物流公司" },
        { field: "kilometers", fieldName: "公里数" },
        { field: "insuranceFee", fieldName: "保险费" },
        { field: "carPrice", fieldName: "车价" },
        { field: "createdAt", fieldName: "创建时间" },
        { field: "updatedAt", fieldName: "修改时间" },
      ], // 表格头
      tableData: [], //表格数据
      Usersbxx: {
        //总申报数量信息
        zsbsl: "",
        bfhj: "",
        sxfhj: "",
      }, // 表格申报信息展示数据 从table总获取
      labelPosition: "right",
      formLabelAlign: {
        current: 1,
        size: 50,
        freightType:'1', //险种类型
        logisticsCompany: "",
       
      },
      options: [{
          value: '1',
          label: '新车货运险保费'
        }, {
          value: '2',
          label: '二手车货运险保费'
        }],
      form: {},

      isLp: false, //true是理赔 false不是理赔
      fileList: [], // 用于 理赔存储上传的文件列表
      iconformData: [{ url: "", name: "" }],
      upLoadData: {
        materialUploadDetailParams: [
          {
            sbrId: "",
            file: "",
            fileName: "",
            fileType: "",
          },
        ],
      }, //用于虚拟添加上传附件
      tjfjVisble: false, //添加附件

      isAdd: false, //true是添加 false不是添加
      url:window.ipConfigUrl.baseURL1+"import/freightIns/insurancePrice"
    };
  },
  computed: {},
  methods: {
    // 子组件传参 理赔
    openLp(message) {
      this.isLp = message;
    },
    // 取消 取消理赔
    resetForm() {
      this.isLp = false;
    },
    //文件列表移除文件时的钩子
    handleRemove() {
      this.iconformData = [];
    },

                // 下载xlsx 模版
                downloadTemplate() {
                let fileName = "货运险保费模板.xlsx";
                downLoad({fileName: fileName})
                    .then(res => {
                        console.log('res', res);
                      let blob = new Blob([res], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                      let url = window.URL.createObjectURL(blob);
                      let link = document.createElement('a');
                      link.href = url;
                      link.download = '货运险保费模板.xlsx';
                      link.click();
                      window.URL.revokeObjectURL(url);
                    })
            },
    // 查询
    onSubmit() {
      console.log("submit!");
      // 查询发送请求参数给父组件
      // this.$emit("onQuery", this.formInline);
      console.log(this.formLabelAlign);
      // 初始化请求table数据
      this.allData(this.formLabelAlign);
    },
    // 页数变动
    handleSizeChange(e) {
      this.PageSize = e;
      this.formLabelAlign.size=e
      this.allData(this.formLabelAlign);
    },
    // 页码变动
    handleCurrentChange(e) {
      this.currentPage = e;
      this.formLabelAlign.current=e
      this.allData(this.formLabelAlign);
    },
    // 删除货运险保费设置
    clickNowRow(index, row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row);
          let obj = row.id;
          deleteBfsz({id:obj})
          .then((res) => {
            if (res.code == 200) {
              console.log(res, "删除提示");

              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.allData(this.formLabelAlign); 
            }
            console.log(res);
          });
        })
    },
    // 点击 上传附件
    tjfj() {
      this.fileList = [];
      this.tjfjVisble = true;
    },
    //文件列表移除文件时的钩子
    handleRemove() {
      console.log("移除图片列表");
      this.iconformData = [];
    },
    handleBeforeUpload(file) {  
    this.queryDownFile=window.ipConfigUrl.baseURL1+'import/freightIns/insurancePrice'         
      this.customUpload(file,this.queryDownFile);  
      // 返回false以阻止Element UI的默认上传行为  
      return false;  
    }, 
    customUpload(file,downFile) {  
      // 自定义上传逻辑，可以添加username到请求体中  
      const formData = new FormData();  
      formData.append('file', file); // 假设file.raw是文件对象，具体取决于你的实际情况  
      formData.append('freightType', this.formLabelAlign.freightType);  
  
      // 使用axios或其他HTTP客户端发送请求  
      axios.post(downFile, formData, {  
        headers: {  
          'Content-Type': 'multipart/form-data',  
        },  
      })  
      .then(response => {  
        if (response.data.code==200) {
          this.$message({
            type:'success',
            message:response.data.message,
          });
                              // 重新获得表格数据
        this.allData(this.formLabelAlign);   
        }else{
          this.$message({
            type:'error',
            message:'提交错误',
          });
        }
        this.tjfjVisble = false;
      })  
      .catch(error => {  
        // 处理上传失败后的逻辑  
        console.error(error);  
      });  
    },

    // 编辑
    handleModify(index, row) {
      console.log(row);

      this.isLp = true;
      this.isAdd = false;
      this.form = row;
      this.form.operationType='update'
      console.log(this.form);
    },
    // 编辑 保存
    bcbj() {
      newCarxg(this.form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("编辑保存成功");
          this.isLp = false; //退出编辑页
          this.allData(this.formLabelAlign); // 刷新表格初始化
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 编辑 取消
    qxbj() {
      this.isLp = false; //退出编辑页
    },
    // 添加
    tjxx() {
      this.isLp = true;
      this.isAdd = true;
      this.form = {}; //清空
            this.form.operationType='save'
    },
    // 获取表单所有数据
    allData(e) {
      newCardata(e).then((res) => {
        if (res.code == 200) {
          console.log(res.data, "表格数据");
          this.tableData = res.data.records;
          this.totalCount=res.data.total

          this.$message.success("获取表格数据成功");
        }
      });
    },
  },
  mounted() {
    // 初始化请求table数据
    this.allData(this.formLabelAlign);
  },
};
</script>
<style lang="less" scoped>
// 下载栏
.xzl {
  border: 1px, solid, rgb(221, 221, 221);
  display: flex;

  // 下载模版
  .xzl-xzmb {
    font-size: 12px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(30, 159, 255);
    font-weight: 900;
    cursor: pointer;
    margin-right: 10px;
  }

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}

// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
</style>

<template>
  <div>
    <div v-show="!isBj">
      <!-- 下载模块 -->
      <div class="cxl">
        <el-form
              :inline="true"
              :model="formLabelAlign"
              class="demo-form-inline"
              clearable
            >
              <el-form-item label="险种类型" class="custom-form-item">

                <el-select clearable v-model="formLabelAlign.insName" placeholder="请选择险种类型">
    <el-option
      v-for="item in directivesOptions"
      :key="item.value"
      :label="item.label"
                        class="cxl-input"
      :value="item.value">
    </el-option>
  </el-select>
              </el-form-item>
              <el-form-item label="操作人" class="custom-form-item">
                <el-input
                  v-model="formLabelAlign.username"
                  placeholder="请输入操作人"
                  class="cxl-input"
                ></el-input>
              </el-form-item>
              <el-form-item class="cxl-form-btns">
                <el-button class="cxl-form-btn" @click="onQuery"
                  >查询</el-button
                >
                <div class="xzl-div-btn">
              <el-button class="xzl-div-btns" @click="tjfj">
                <i style="font-size: 20px" class="el-icon-upload"></i>上传excel
              </el-button>
              <!-- 上传excel -->
              <el-dialog
                title="上传excel"
                :visible.sync="tjfjVisble"
                :close-on-click-modal="false"
              >
                <div style="display: flex; justify-content: center">
                  <el-upload
                    style="width: auto"
                    class="centerImg"
                    :file-list="fileList"
                    ref="uploadicon"
                    :action='url'
                    :on-remove="handleRemove"
                    :before-upload="handleBeforeUpload"
                    :auto-upload="true"
                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple
                    drag
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或<em>点击上传</em>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                      只能上传xlsx文件，且不超过1MB
                    </div>
                  </el-upload>
                </div>
              </el-dialog>
            </div>
              </el-form-item>
            </el-form>
        <!-- 添加选择用户框 -->
        <el-dialog title="选择添加用户类型" :visible.sync="dialogFormVisible">
  <el-form :model="formQryhlx">
    <!-- :label-width="formLabelWidth" -->
    <el-form-item label="用户类型" >
      <el-select v-model="formQryhlx.userType" placeholder="请选择用户类型">
        <el-option label="管理员" value="1"></el-option>
        <el-option label="客户" value="2"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="qdtj">确 定</el-button>
  </div>
</el-dialog>
      </div>
      <!-- 查询栏 -->
      <!-- table模块  ref表格数据-->
      <div style="margin-top: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
        >
          <el-table-column
            align="center"
            header-align="center"
            label="ID"
            prop="id"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="操作人"
            prop="username"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="角色名称"
            prop="roleName"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="险种名称"
            prop="insName"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="上传时间"
            prop="operationTime"
          >
          </el-table-column>
        
          <el-table-column
            header-align="center"
            align="center"
            label="操作"
            width="150"
            style="display: flex; justify-content: left"
          >
            <template slot-scope="scope">
              <el-button
                
                @click="handleEdit(scope.$index, scope.row)"
              >详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <div v-if="total" style="text-align: left; margin-top: 5px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 理赔 -->
    <!-- 编辑信息 -->
    <div v-show="isBj" style="width: 100%; border: 1px solid #eee">
      <div
        style="
          font-size: 14px;
          font-weight: 900;
          background-color: rgb(240, 250, 254);
          line-height: 36px;
          height: 36px;
          padding-left: 12px;
          border-bottom: 1px solid #eee;
        "
      >
       {{ isAdd ? pushYh : '运输管理历史数据详情'}} 
      </div>
      <div style="margin: 20px">
        <el-table :data="xqtableData" border style="width: 100%">
      <el-table-column
      v-for="(item, index) in xqtableTop"
      align="center"
      header-align="center"
      :key="index"
      :label="item.fieldName"
      :prop="item.field"
    >
  </el-table-column>
    </el-table>
    <div
            v-if="xqtableData.length"
            style="text-align: left; margin-top: 5px"
          >
            <el-pagination
              @size-change="handleSizeChangeOne"
              @current-change="handleCurrentChangeOne"
              :current-page="currentPageOne"
              :page-sizes="pageSizesOne"
              :page-size="PageSizeOne"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalOne"
            >
            </el-pagination>
          </div>
    <el-button @click="qxbj">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
// 引入table top 组件
import yhglTableTop from "@/components/Public/Tabletop/yhglForm.vue";
// 引入table组件
import xtTable from "@/components/Public/Table/index.vue";
import axios from 'axios'; 
import {
  queryHistoryYSData,
  queryHistorylPData,
  queryDataUp
} from "@/axios/Xtsz/Ywgl/lsjl.js"; //接口
import * as XLSX from "xlsx"; //导出类型
export default {
  components: {
    xtTable,
    yhglTableTop,
  },
  data() {
    return {
      directivesOptions:[
        {value: '商业险', label: '商业险'},
        {value: '车辆雇主责任险', label: '车辆雇主责任险'},
        {value: '交强险', label: '交强险'},
        {value: '货运险', label: '货运险'},
        {value: '财产险', label: '财产险'},
        {value: '责意险', label: '责意险'},
        {value: '货运三者险', label: '货运三者险'},
        {value: '商品车运输险', label: '商品车运输险'},
      ],
      options: {
        "商业险": "01",
"车辆雇主责任险": "02",
"交强险": "03",
"货运险": "04",
"财产险": "05",
"责意险": "06",
"货运三者险": "07",
"商品车运输险": "08",

      },
      templateId: "1", // 这个就是页面的ref 用来判定需要哪一个模版
      tableData: [], //表格数据

      status: [], //请选择所属角色
      formInline: {
        roleName: "",
        current:'',
        size:'',
      }, //查询项
      formLabelAlign: {
        current: 1,
        size: 50,
        insName:'', //险种类型
        affairType:'2',
        username: "",
        operMark: ""
      },
      isBj: false, //true是编辑 false不是编辑
      currentPage: 1,
      PageSize: 10,
      pageSizes: [10, 20, 30, 40],
      isAdd: false, //true是添加 false不是添加
      dialogFormVisible: false,//添加弹框显示
      formQryhlx: {
        userType: '',
        },
        // 提示用户当前添加用户是谁
      pushYh:'',
      userTypeList: [],
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      total: 0,
      fileList: [], // 用于 理赔存储上传的文件列表
      tjfjVisble: false, //添加附件
      url:window.ipConfigUrl.baseURL1+"import/history/transport",
      xqtableTop:[],
      xqtableData:[],
            // 默认显示第几页
            currentPageOne: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCountOne: 1,
      // 个数选择器（可修改）
      pageSizesOne: [50,100,200],
      // 默认每页显示的条数（可修改）
      PageSizeOne: 50,
      totalOne:0,
      NowoperMark:''
    };
  },
  methods: {

    handleSizeChangeOne(val) {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
    });
      queryHistorylPData({affairType: "2",current: 1,size:val,operMark: this.NowoperMark})
      .then((res)=>{
        this.xqtableData = res.data.records;
        this.totalOne = res.data.total;
        loading.close();
      })
    },
    handleCurrentChangeOne(val) {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
    });
      queryHistorylPData({affairType: "2",current: val,size:50,operMark: this.NowoperMark})
      .then((res)=>{
        this.xqtableData = res.data.records;
        this.totalOne = res.data.total;
        loading.close();
      })
    },
        // 点击 上传附件
        tjfj() {
          if (this.formLabelAlign.insName == "") {
            this.$message({
              message: "请选择险种类型，再进行上传",
              type: "warning",
            });
            return;
          }
      else{
            this.fileList = [];
            this.tjfjVisble = true;
          }

    },
        //文件列表移除文件时的钩子
        handleRemove() {
      this.iconformData = [];
    },
    handleBeforeUpload(file) {  
    this.queryDownFile=window.ipConfigUrl.baseURL1+'import/history/transport'         
      this.customUpload(file,this.queryDownFile);  
      // 返回false以阻止Element UI的默认上传行为  
      return false;  
    }, 
    customUpload(file,downFile) {  
    // 自定义上传逻辑，可以添加username到请求体中  
    const formData = new FormData();  
    formData.append('file', file); // 假设file.raw是文件对象，具体取决于你的实际情况  
    formData.append('formDataType', this.formLabelAlign.insName);  
    formData.append('username', JSON.parse(localStorage.getItem("username")));  
    // 使用axios或其他HTTP客户端发送请求  
    axios.post(downFile, formData, {  
      headers: {  
        'Content-Type': 'multipart/form-data',  
      },  
    })  
    .then(response => {  
      if (response.data.code==200) {
        this.$message({
          type:'success',
          message:response.data.message,
        });
                            // 重新获得表格数据
                            this.queryUserData();   
      }else{
        this.$message({
          type:'error',
          message:'提交错误',
        });
      }
      this.tjfjVisble = false;
    })  
    .catch(error => {  
      // 处理上传失败后的逻辑  
      console.error(error);  
    });  
  },
    // 导出xt-table中的table数据
    handleExport() {
      // 从TableComponent获取数据
      const tableData = this.$refs.tableComponent.getTableData();
      // const tableTop = this.$refs.tableComponent.getTableTop()[0];
      const tableValue = this.$refs.tableComponent.getTableTop()[1];
      // 将数据转换为工作表
      const ws = XLSX.utils.aoa_to_sheet([
        // tableTop,
        ...tableData.map((row) => {
          const values = tableValue.map((header) => {
            // 将 header 转换为小写（如果原对象的键名也是小写的话，这一步可以省略）
            // 但由于你的键名是大写和混合的，所以这里不转换
            // 直接使用原 header 作为键名来访问对象属性
            return row[header] || ""; // 如果属性不存在，则返回空字符串
          });
          return values;
        }),
      ]);
      // 创建一个工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // 导出xlsx文件
      XLSX.writeFile(wb, `表格数据_${this.templateId}.xlsx`);
    },

    // 添加用户操作
    tjyh() {
      // 清空form内容
      this.form = {};
      this.formQryhlx.userType=''
      // 打开编辑弹框
      this.dialogFormVisible=true;

      this.isAdd = true; //添加用户标识
    },
    // 确定添加用户
    qdtj(){
      if (this.formQryhlx.userType=='') {
        this.$message.error('请选择用户类型再进行添加');
      }else{
        this.dialogFormVisible=false;
        if(this.formQryhlx.userType=='1'){
          this.pushYh='添加-管理员'
        }else{this.pushYh='添加-客户'}
        GetUserType(this.formQryhlx).then((res) => {
          if (res.code == 200) {
            this.form.userType=this.formQryhlx.userType;
            this.$message.success("选择成功");
            this.userTypeList=res.data;
            console.log(this.userTypeList,'普通人');
            
          }else{
            this.$message.error(res.msg);
          }
        });
      this.isBj = true;
      }
    },
    // 取消编辑用户
    qxbj() {
      this.isBj = false;
      // this.form = {}; // 清空表单数据
      
    },
    // 查询
    onQuery() {
      queryHistoryYSData(this.formLabelAlign).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.formLabelAlign.size = val;
      this.formLabelAlign.current=1;
      this.PageSize = val;
      queryHistoryYSData(this.formLabelAlign).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          console.log(res.data);
        }
      });
    },
    handleCurrentChange(val) {
      this.formLabelAlign.size = 10;
      this.formLabelAlign.current=val;
      queryHistoryYSData(this.formLabelAlign).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          console.log(res.data);
        }
      });
    },
    //初始化获取角色
    queryUserData() {
      this.formLabelAlign.size = 10;
      this.formLabelAlign.current=1;
      queryHistoryYSData(this.formLabelAlign).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          console.log(res.data);
        }
      });
    },
    // 编辑用户信息
    handleEdit(row, rowData) {
      this.isAdd=false;
      this.isBj = true; //切换编辑添加页面
           // 加载
           const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
    });
      let insname= this.options[rowData.insName]
      //详情表格头
      queryDataUp({pageType: "1",insType: insname})
          .then((res) => {
             if (res.code == 200) {
              this.xqtableTop = res.data;
              this.NowoperMark=rowData.operMark;
              loading.close();
              queryHistorylPData({affairType: "2",current: 1,size:30,operMark: rowData.operMark})
              .then((res) => {
                this.xqtableData = res.data.records;
                this.totalOne = res.data.total;
              })
             } else {
               this.$message.error(res.message);
             }
           })
           .catch((err) => {
             console.log(err.message);
             loading.close();
            this.$message.error(err.message);
           });

    },
    // 保存编辑用户信息
    onSubmit() {
      console.log(this.form,'this.form');
      const mobilePattern = /^1(3|4|5|6|7|8|9)\d{9}$/;  
      const PhoneTest =mobilePattern.test(this.form.phone);  
      
     
       if (this.form.insName == "") {
         this.$message.error("请选择险种类型");
         return;
       }

      // 编辑
        console.log("编辑");
        console.log(this.form);
        
        queryDataUp({pageType: "1",insType: this.form.insName})
          .then((res) => {
             if (res.code == 200) {
               this.$message.success("编辑保存成功");
               this.isBj = false; //退出编辑页
               this.form.newPassword='';
               this.form.oldPassword='';
               this.queryUserData(); // 刷新表格初始化
             } else {
               this.$message.error(res.message);
             }
           })
           .catch((err) => {
             console.log(err.message);
            this.$message.error(err.message);
           });
    },

  },
  mounted() {
    this.queryUserData();
    //初始化展示操作人

  },
};
</script>
<style lang="less" scoped>


// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 130px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
     
  }
   // 上传+导入
   .xzl-div-btn {
    position: absolute;
    bottom: -3px;
    right: -130px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
/deep/.el-form-item {
  //   margin-bottom: 0px; //清空表格行间距margin
  // line-height:58px !important;
}

/deep/.el-form-item__label {
  display: block;
  height: 100%;
  //   border-top: 1px solid #eee !important;
  /* 举例，根据需要设置 */
  display: flex;
  align-items: center;

  /* 垂直居中内容 */
}

/deep/.el-form-item__content {
  //   border: 1px solid #eee;
}
</style>

// 调用axios封装
import request from '@/axios/api'


// 历史数据运输 上传
export const HistoryDatays = data => {
    return request({
        url: '/import/history/transport',
        data,
        method: 'post'
    })
}
// 历史数据理赔 上传
export const HistoryDatalp = data => {
    return request({
        url: '/import/history/claims',
        data,
        method: 'post'
    })
}
// 查询历史数据 运输  + 理赔
export const queryHistoryYSData = publishActivity => {
    const data = publishActivity
    return request({
        url: '/import/history/records',
        data,
        method: 'post'
    })
}

// 详情数据标头接口
export const queryDataUp = publishActivity => {
    const data = publishActivity
    return request({
        url: '/tms/function/config',
        data,
        method: 'post'
    })
}
// 详情数据 修改接口 
export const queryHistorylPData = publishActivity => {
    const data = publishActivity
    return request({
        url: '/import/history/detail',
        data,
        method: 'post'
    })
}
